<template>
  <comparator-agents
    :uuid="uuid"
    actionID="8"
    i18nKey="less_than"
    :middlewareID="middlewareID"
    :agentID="agentID"
    v-model="agent"
    ref="comparator-agent-component"
    @saved="onSaved"
    :agentBlockID="agentBlockID"
    @agentClosed="$emit('agentClosed')"
    :variables="variables"
  >
  <template #icon>

      <b-col md="4" class="justify-content-end d-flex ">
        <h5 class="mt-0">{{ $t(`common.terms.first_num`) }}</h5>
      </b-col>
      
      <b-col md="1" class="justify-content-center d-flex">
        
        <b-iconstack scale="0.8" class="mr-1 mt-0" shift-v="8">
          <feather-icon stacked icon="ChevronLeftIcon" size="38" class="text-success" />
          <b-iconstack rotate="45" scale="1" shift-v="-25" shift-h="11">
          </b-iconstack>
        </b-iconstack>
      </b-col>

      <b-col md="4" class="justify-content-start d-flex ">
        <h5 class="mt-0 ">{{ $t(`common.terms.second_num`) }}</h5>
      </b-col>

    </template>
    
  
  </comparator-agents>
</template>

<script>

import ComparatorAgents from './ComparatorAgents.vue';
import NormalizedAgent from "@/custom/class/NormalizedAgent.js";
import {BCol, BIcon} from 'bootstrap-vue';

  import AgentsMixin from '../AgentsMixin'

export default {
  mixins: [AgentsMixin],

    components: {
      ComparatorAgents,
      BCol,
      BIcon,
    },
    props: {
      middlewareID: {
        type: Number,
        required: true,
      },
      agentID: {
        type: Number,
        default: undefined,
      },
      value: {
        type: NormalizedAgent,
        default: undefined
      },
      uuid: {
        type: String,
        required: true,
      },
      width: {
        type: String,
        default: "40%"
      },
      agentBlockID: {
        type: [ Number, String ],
        default: 1,
      },
      variables:{
        type: Object,
        default: undefined
      }
    },
    computed: {
      agent: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      toSaveRegs(){
        return this.$refs['comparator-agent-component'].toSaveRegs
      }
    },
    methods: {
      onSaved(payload) {
        this.$emit('saved', payload)
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>